module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"space":[0,4,8,16,32,64,128,256,512],"fonts":{"body":"\"Kumbh Sans\", sans-serif","heading":"\"Kumbh Sans\", sans-serif","monospace":"Menlo, monospace"},"fontSizes":[12,14,16,20,24,32,48,64,96],"fontWeights":{"body":400,"heading":700,"bold":700},"lineHeights":{"body":1.5,"heading":1.125},"colors":{"text":"#ffffff","background":"#648381","primary":"#07c","secondary":"#30c","muted":"#f6f6f6"},"styles":{"root":{"fontFamily":"body","lineHeight":"body","fontWeight":"body"},"h1":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":5},"h2":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":4},"h3":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":3},"h4":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":2},"h5":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":1},"h6":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":0},"p":{"color":"text","fontFamily":"body","fontWeight":"body","lineHeight":"body"},"a":{"color":"inherit"},"pre":{"fontFamily":"monospace","overflowX":"auto","code":{"color":"inherit"}},"code":{"fontFamily":"monospace","fontSize":"inherit"},"table":{"width":"100%","borderCollapse":"separate","borderSpacing":0},"th":{"textAlign":"left","borderBottomStyle":"solid"},"td":{"textAlign":"left","borderBottomStyle":"solid"},"img":{"maxWidth":"100%"}},"sizes":{"container":"720px"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/pacific.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dc4055d0c6c9ad48677afd0e9b976242"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
